import dayjs from 'dayjs'
import clone from '../utils/clone'
import isBetween from 'dayjs/plugin/isBetween'
import timezone from 'dayjs/plugin/timezone'
import utc from "dayjs/plugin/utc"
import advanced from "dayjs/plugin/advancedFormat"
dayjs.extend(utc)
dayjs.extend(advanced)
dayjs.extend(isBetween)
dayjs.extend(timezone)

const getTimeZone = (date) => {
  // let timeZone = dayjs.tz.guess()

  Intl.DateTimeFormat().resolvedOptions().timeZone
  let original = typeof date === 'string' ? date : dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ')
  date = typeof date === 'string' ? new Date(date) : date
  // console.log(dayjs(date).toDate().toLocaleDateString(), {original})
  // console.log(typeof date,date)
  // console.log(dayjs(date).format('Z'))
  // console.log(dayjs(date).format('z'))
  // console.log(dayjs(date).format('zzz'))
  // console.log('----------------', date.toLocaleTimeString('en-us',{timeZoneName:'short'}))
  let timezoneFreeDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss')
  // console.log(dayjs(`${timezoneFreeDate} PST8PDT`) === dayjs(date).tz('PST8PDT',true).format())
  // console.log(dayjs(`${timezoneFreeDate} PST8PDT`) ,dayjs(date).tz('PST8PDT',true).format())
  let EST5EDT = dayjs(date).tz('EST5EDT').format('YYYY-MM-DDTHH:mm:ssZ')
  let CST6CDT = dayjs(date).tz('CST6CDT').format('YYYY-MM-DDTHH:mm:ssZ')
  let MST7MDT = dayjs(date).tz('MST7MDT').format('YYYY-MM-DDTHH:mm:ssZ')
  let PST8PDT = dayjs(date).tz('PST8PDT').format('YYYY-MM-DDTHH:mm:ssZ')
  let HST = dayjs(date).tz('HST').format('YYYY-MM-DDTHH:mm:ssZ')
  console.log(original = PST8PDT)
  console.log(JSON.stringify({
    original,
    timezoneFreeDate,
    EST5EDT,
    CST6CDT,
    MST7MDT,
    PST8PDT,
    HST,
  }, null, 2))
  if (original === EST5EDT) {
    return 'EST5EDT'
  }
  if (original === CST6CDT) {
    return 'CST6CDT'
  }
  if (original === MST7MDT) {
    return 'MST7MDT'
  }
  if (original === PST8PDT) {
    return 'PST8PDT'
  }
  if (original === HST) {
    return 'HST'
  }
  return dayjs(date).format('Z')
}

const validateTimeRange = (startTime, endTime) => {
  if (!startTime) {
    return true
  }
  if (!endTime) {
    return true
  }
  let offsetDay = 0
  if (startTime.length === 5) {
    startTime += ':00'
  }
  if (endTime.length === 5) {
    endTime += ':00'
  }
  if (endTime === '00:00:00') {
    offsetDay = 1
  }
  let startDate = dayjs().hour(+startTime.split(":")[0]).minute(+startTime.split(":")[1]).second(0)

  let endDate = dayjs().add(offsetDay, 'day').hour(+endTime.split(":")[0]).minute(+endTime.split(":")[1]).second(0)
  let valid = endDate.isAfter(startDate)
  return valid
}

const formatDays = (dayArray) => {
  if (!dayArray || !dayArray.length) {
    return null
  }
  const days = JSON.stringify(clone(dayArray).sort())
  const Weekdays = JSON.stringify(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].sort())
  const Weekend = JSON.stringify(['Saturday', 'Sunday'].sort())
  const MonSat = JSON.stringify(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", 'Saturday'].sort())
  const Daily = JSON.stringify(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", 'Saturday', 'Sunday'].sort())
  if (days == Weekdays) {
    return 'Weekdays'
  } else if (days == MonSat) {
    return 'Mondat-Saturday'
  } else if (days == Weekend) {
    return 'Weekends'
  } else if (days == Daily) {
    return 'Daily'
  }
  return dayArray.join(', ')
}

const beforeNow = (date) => {
  if (!date) {
    return false
  }
  let dateString = Array.isArray(date) ? date[date.length - 1] : date
  return dayjs().isAfter(dayjs(dateString).endOf('day'))
}


const checkMatchedSchedule = (scheduleItem, date) => {
  let now = dayjs(date || undefined)
  console.log('CHECKING FOR ', date)
  let result = {
    now: date ? false : true,
    testDate: now.format('YYYY-MM-DD'),
    testTime: now.format('h:mma'),
    testDateRaw: now.toDate(),
    itemId: scheduleItem.id,
    timeId: null,
    date: false,
    time: false,
    status: 'closed',
    reason: scheduleItem.status === 'closed' ? scheduleItem.reason : null,
    customReason: null,
    assumed: false,

  }
  let dateParts = scheduleItem.date && !Array.isArray(scheduleItem.date) ? scheduleItem.date.split('-') : null
  let thisYearDate = dateParts ? dayjs(`${now.format('YYYY')}-${dateParts[1]}-${dateParts[2]}`).format('YYYY-MM-DD') : null
  switch (scheduleItem.type) {
    case 'day':
      if (scheduleItem.days.indexOf(now.format('dddd')) > -1) {
        result.date = true
        result.customReason = scheduleItem.reason === 'custom' ? scheduleItem.customReason : null

      }
      break
    case 'nth':
      if (scheduleItem.days.indexOf(now.format('dddd')) > -1) {
        result.date = true
        result.customReason = scheduleItem.reason === 'custom' ? scheduleItem.customReason : null
      }
      break
    case 'dateRange':
      if (now.isBetween(scheduleItem.date[0], scheduleItem.date[1], null, '[]')) {
        if (scheduleItem.days && scheduleItem.days.length) {
          result.date = scheduleItem.days.indexOf(now.format('dddd')) > -1
        } else {
          result.date = true
          result.customReason = scheduleItem.reason === 'custom' ? scheduleItem.customReason : null

        }
      }
      break
    case 'annualDate':
    case 'date':
      if (thisYearDate === now.format('YYYY-MM-DD')) {
        result.date = true
        result.customReason = scheduleItem.reason === 'custom' ? scheduleItem.customReason : null
      }
      break
  }
  if (scheduleItem.times && result.date) {
    scheduleItem.times.forEach(time => {
      let startTimeParts = time.startTime.split(':')
      let endTimeParts = time.endTime.split(':')
      let startTime = dayjs(now).hour(startTimeParts[0]).minute(startTimeParts[2]).second(0)
      let endTime = dayjs(now).hour(endTimeParts[0]).minute(endTimeParts[2]).second(0)
      let match = now.isBetween(startTime, endTime, null, '[)')
      if (match) {
        result.time = true
        result.timeId = time.id
        result.status = time.status
        result.reason = time.reason
        result.customReason = time.reason === 'custom' ? time.customReason : null
      }
    })
  }
  if (!result.reason && result.status === 'closed') {
    result.reason = 'afterhours'
    result.assumed = true
  }
  // console.log('RETURN checkMatchedSchedule', result)
  return result
}

const isCurrent = (scheduleItem) => {
  let result = {
    date: false,
    time: false,
    status: 'closed',
    reason: scheduleItem.status === 'closed' ? scheduleItem.reason : null,

  }
  let now = dayjs()
  let dateParts = scheduleItem.date && !Array.isArray(scheduleItem.date) ? scheduleItem.date.split('-') : null
  let thisYearDate = dateParts ? dayjs(`${now.format('YYYY')}-${dateParts[1]}-${dateParts[2]}`).format('YYYY-MM-DD') : null
  switch (scheduleItem.type) {
    case 'day':
      if (scheduleItem.days.indexOf(now.format('dddd')) > -1) {
        result.date = true
      }
      break
    case 'nth':
      if (scheduleItem.days.indexOf(now.format('dddd')) > -1) {
        result.date = true
      }
      break
    case 'dateRange':
      if (now.isBetween(scheduleItem.date[0], scheduleItem.date[1], null, '[]')) {
        if (scheduleItem.days && scheduleItem.days.length) {
          result.date = scheduleItem.days.indexOf(now.format('dddd')) > -1
        } else {
          result.date = true
        }
      }
      break
    case 'annualDate':
    case 'date':
      if (thisYearDate === now.format('YYYY-MM-DD')) {
        result.date = true
      }
      break
  }
  if (scheduleItem.times && result.date) {
    scheduleItem.times.forEach(time => {
      let startTimeParts = time.startTime.split(':')
      let endTimeParts = time.endTime.split(':')
      let startTime = dayjs(now).hour(startTimeParts[0]).minute(startTimeParts[2]).second(0)
      let endTime = dayjs(now).hour(endTimeParts[0]).minute(endTimeParts[2]).second(0)
      let isNow = now.isBetween(startTime, endTime, null, '[)')
      if (isNow) {
        result.time = isNow
        result.status = time.status
        result.reason = time.reason
      }
    })
  }
  if (!result.reason && result.status === 'closed') {
    result.reason = 'assumedClosed'
  }
  return result
}

export default {
  validateTimeRange,
  formatDays,
  beforeNow,
  isCurrent,
  getTimeZone
}

export {
  validateTimeRange, formatDays, beforeNow, isCurrent, getTimeZone, checkMatchedSchedule
}
