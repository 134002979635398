<template>
  <v-select
  label="Type"
  :items="items"
  v-bind:value="value"
  @change="change"
   prepend-icon="mdi-calendar-cursor"
   open-on-clear
   ref="select"
  ></v-select>
</template>

<script>
export default {
    data(){
        return {
            items:[
            {text: 'On Date', value:'date'},
            {text: 'Annual Date', value:'annualDate'},
            {text: 'nth Day', value:'nthDay'},
            {text: 'Date Range', value:'dateRange'},
            {text: 'Day of the Week', value:'day'},
        ]}
    },
    props: ['value'],
    methods:{
        change(evt){
            this.$emit('input', evt)
        }
    },
    mounted(){
        this.$nextTick(()=>{
            if (!this.value){
                    this.$refs.select.focus()
                    this.$refs.select.activateMenu()            }

        })
    }
}
</script>

<style>

</style>