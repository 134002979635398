<template>
  <div class="d-flex">
    <v-select
      label="Status"
      :items="status"
      v-bind:value="value.status"
      @change="change('status', $event)"
    >
      <template v-slot:prepend>
        <v-icon :color="value.status == 'open' ? 'green' : null"
          >mdi-calendar-clock</v-icon
        >
      </template>
    </v-select>
    <v-select
      label="Messaging"
      v-if="value.status === 'open' && !noOpenReason"
      class="flex-grow-1"
      :items="openReasons"
      v-bind:value="value.reason"
      @change="change('reason', $event)"
      prepend-icon="mdi-calendar-check"
    >
      <template v-slot:prepend>
        <v-icon color="green">mdi-calendar-check</v-icon>
      </template>
    </v-select>
    <v-select
      label="Reason"
      class="flex-grow-1"
      v-if="value.status === 'closed'"
      :items="closedReasons"
      v-bind:value="value.reason"
      @change="change('reason', $event)"
      prepend-icon="mdi-calendar-minus"
    ></v-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: {
        status: null,
        reason: null,
      },
      status: [
        { text: "Open", value: "open" },
        { text: "Closed", value: "closed" },
      ],
      closedReasons: [
        { text: "Afterhours", value: "afterhours" },
        { text: "Holiday", value: "holiday" },
        { text: "Special", value: "special" },
        { text: "Training", value: "training" },
        { text: "Outage", value: "outage" },
        { text: "No Phones", value: "noPhones" },
        { text: "Custom", value: "custom" },
      ],
      openReasons: [
        { text: "Normal", value: null },
        { text: "Holiday", value: "holiday" },
        { text: "Special", value: "special" },
        { text: "Training", value: "training" },
        { text: "Outage", value: "outage" },
        { text: "No Phones", value: "noPhones" },
        { text: "Custom", value: "custom" },
      ],
    };
  },
  props: ["value", "noOpenReason"],
  watch: {
    selected: {
      deep: true,
      handler(val, oldVal) {
        console.log(val, oldVal);
        if (oldVal && val.reason !== oldVal.reason) {
          this.selected.reason = null;
        }
      },
    },
  },
  methods: {
    change(key, value) {
      this.$emit("input", { ...this.value, ...{ [key]: value } });
    },
  },
};
</script>

<style>
</style>