<template>
  <v-dialog transition="dialog-top-transition" max-width="800"
        v-model="dialog"
        persistent
  >
    <template  v-slot:activator="{ on, attrs }">
      <v-btn small color="white" dark icon  v-bind="attrs" v-on="on">
        <v-icon dark>mdi-calendar-edit</v-icon>
      </v-btn>
    </template>
      <v-card>
        <v-toolbar color="primary" dark>Opening from the bottom</v-toolbar>
        <v-card-text>
          <div class="text-h2 pa-12" v-if="schedule">
            <v-text-field v-model="schedule.name" label="Name"></v-text-field>
            <field-timezone-select v-model="schedule.timezone" label="Timezone"/>
            <field-bright-pattern-service-select v-model="schedule.services" />
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
            <v-btn
            text
            @click="cancel"
            >Cancel</v-btn
          >
          <v-spacer/>
           <v-btn
            color="success"
            @click="change"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import sampleDB from "../../sampleDB";
import FieldBrightPatternServiceSelect from "../fields/FieldBrightPatternServiceSelect.vue";
import FieldTimezoneSelect from "../fields/FieldTimezone.vue";
export default {
  components: { FieldBrightPatternServiceSelect, FieldTimezoneSelect },
  data() {
    return {
      dialog: false,
      schedule: null,
    };
  },
  props: ["value"],
  watch: {
    value: {
      immediate: true,
      handler(val) {
        console.log('VALUE CHANGED EDIT')
        this.schedule = JSON.parse(JSON.stringify(val));
      },
      deep: true
    },
  },
  computed: {
      valid(){
          return true
      },
    services() {
      let services = [];
      sampleDB.clients.forEach((client) => {
        client.brightPattern.services.forEach((service) => {
          let item = {
            text: `${client.name} - ${service}`,
            value: service,
          };
          services.push(item);
        });
      });
      return services;
    },
  },
  methods: {
    change() {
      let result = JSON.parse(JSON.stringify(this.schedule))
      this.$emit("input", result);
      this.dialog = false
    },
    cancel() {
      this.schedule = JSON.parse(JSON.stringify(this.value));
      this.dialog = false
    },
  },
};
</script>

<style>
</style>