export default {
    clients: [
        {
            access: {
                clients: ['chanel', 'noRestriction'],
                roles: {
                    dev: true,
                    tech: true,
                    exec: true,
                    finance: true,
                    ops: true,
                    wfm: true,
                    client: true,
                    ba: true,
                }
            },
            brightPattern: {
                services: [
                    'Chanel BTQ - Bal Harbour, FL - 305-868-0550',
                    'Chanel BTQ - Sample 1',
                    'Chanel BTQ - Sample 2',
                    'Chanel BTQ - Sample 3',
                    'Chanel BTQ - Sample 4',
                    'Chanel BTQ - Sample 5',
                    'Chanel BTQ - Sample 6',
                    'Chanel BTQ - Sample 7',
                    'Chanel BTQ - Sample 8',
                    'Chanel BTQ - Sample 9',
                    'Chanel BTQ - Orlando, FL - 407-352-5100'
                ],
                teams: [
                    {
                        name: 'Chanel - BA - Overnight',
                        agents: 'sherelyn.dunbar'
                    }

                ],
                serviceGroups: [
                    { name: 'Chanel', RegExp: '(Chanel)' },
                    { name: 'Chanel BTQs', RegExp: '(Chanel BTQ)' },
                    { name: 'Chanel SABs', RegExp: '(Chanel SAB)' },
                ]
            },
            domains: [
                'chanel.com',
                'chanelusa.com'
            ],
            office365Groups: [
                'c03e0b87-c60a-46b8-b247-40af41cb1512',
                '36fd26be-3b80-46c0-9ed9-f953ba23be73',
                '21b5b0ee-fa78-4a1b-a39c-a07dbc120a5e',
                'd2755f34-3526-44d9-bef2-a58c5d4e0a9c',
                '7b88a46f-cdc5-4608-9e3d-3da761ee2494'
            ],
            id: 'chanel',
            zendesks: [
                {
                    subdomain: 'chanelusa',
                    forms: [],
                    brands: [],
                    fields: [],
                }

            ],
            name: 'Chanel'

        }
    ],
    reports: [{
        access: {
            clients: ['chanel', 'noRestriction'],
            roles: {
                dev: true,
                tech: true,
                exec: true,
                finance: true,
                ops: true,
                wfm: true,
                client: true,
                ba: true,
            },
        },
        category: 'WFM',
        description: 'Test Report',
        disabled: false,
        id: '01847fb2-b409-4323-8596-d54faab9ff13',
        metabaseId: 123,
        metabaseType: 'dashboard',
        name: 'WFM Pulse',
        filters: [
            {
                component: 'dateFilter',
                defaultValue: 'past1days',
                label: 'Select Date',
                maxDateRange: 93,
                maxDaysAgo: 83,
                multiple: true,
                metabaseKey: 'date_filter',
                required: true,
                weight: 0,
            },
            {
                component: 'serviceFilter',
                defaultValue: null,
                label: 'Select Service',
                multiple: true,
                metabaseKey: 'service_filter',
                required: true,
                weight: 0,
            },
            {
                component: 'teamFilter',
                defaultValue: null,
                label: 'Select Team',
                multiple: true,
                metabaseKey: 'team_filter',
                required: true,
                weight: 0,
            },
            {
                component: 'agentFilter',
                defaultValue: null,
                label: 'Select BA',
                multiple: true,
                metabaseKey: 'agent_filter',
                required: true,
                weight: 0,
            },
            {
                component: 'zendeskFilter',
                defaultValue: null,
                label: 'Select Zendesk',
                multiple: true,
                metabaseKey: 'zendesk_filter',
                required: true,
                weight: 0,
            }


        ],
    }],
    phone_schedules: [
        {
            access: {
                clients: ['chanel', 'noRestriction'],
                roles: {
                    dev: true,
                    tech: true,
                    exec: true,
                    finance: true,
                    ops: true,
                    wfm: true,
                    client: true,
                    ba: true,
                },
            },
            id: '123',
            items: [
                {
                    name: 'Sample date',
                    id: '123',
                    type: 'date',
                    status: 'open',
                    reason: null,
                    dateString: '2021-08-08',
                    date: '2021-08-08',
                    times: [
                        {
                            status: 'open',
                            reason: null,
                            id: '123',
                            startTime: '12:00:00',
                            endTime: '13:00:00'
                        },
                        {
                            status: 'closed',
                            reason: 'training',
                            id: 'drtsdargtsdfg',
                            startTime: '12:13:00',
                            endTime: '12:18:00'
                        }

                    ]
                },
                {
                    name: 'Sample date range',
                    id: '1234',
                    type: 'dateRange',
                    status: 'open',
                    reason: null,
                    customReason: null,

                    dateString: '2021-08-08~2021-09-19',
                    date: ['2021-08-08','2021-09-19'],
                    days: ['Sunday'],
                    times: [
                        {
                            status: 'open',
                            reason: null,
                            id: 'gfsa',
                            startTime: '12:00:00',
                            endTime: '13:00:00'
                        },
                        {
                            status: 'closed',
                            reason: 'training',
                            id: 'wertwert',
                            startTime: '12:13:00',
                            endTime: '12:18:00'
                        }

                    ]
                },
                {
                    name: 'Sample annual date',
                    id: '12345',
                    customReason: null,

                    type: 'annualDate',
                    status: 'open',
                    reason: null,
                    date: '2021-08-08',
                    dateString: 'XXXX-08-08',
                    times: [
                        {
                            status: 'open',
                            reason: null,
                            id: 'wee',
                            startTime: '12:00:00',
                            endTime: '13:00:00'
                        }
                    ]
                },
                {
                    name: 'Sample day',
                    id: '123567',
                    customReason: null,

                    type: 'day',
                    days: ['Sunday'],
                    status: 'open',
                    reason: null,
                    dateString: null,
                    times: [
                        {
                            status: 'open',
                            reason: null,
                            id: 'jyh',
                            startTime: '12:00:00',
                            endTime: '13:00:00'
                        }
                    ]
                },
                {
                    name: 'Sample day 2',
                    id: '12356447',
                    customReason: null,

                    type: 'day',
                    days: ['Saturday'],
                    status: 'open',
                    reason: null,
                    dateString: null,
                    times: [
                        {
                            status: 'open',
                            reason: null,
                            customReason: null,
                            id: 'jyh',
                            startTime: '09:00:00',
                            endTime: '20:00:00'
                        }
                    ]
                },
                {
                    name: 'Nth day',
                    id: '123sdfasdf',

                    type: 'nthDay',
                    days: ['Sunday'],
                    status: 'open',
                    reason: null,
                    customReason: null,
                    dateString: 'XXXX-11-XX',
                    nth: 4,
                    times: [
                        {
                            status: 'open',
                            reason: null,
                            id: 'iuuio',
                            startTime: '12:00:00',
                            endTime: '13:00:00'
                        }
                    ]
                }

            ],
            name: 'Chanel BTQ - Orlando, FL - 407-352-5100',
            services: ['Chanel BTQ - Orlando, FL - 407-352-5100'],
            timezone: 'EST5EDT'
        },
        {
            access: {
                clients: ['chanel', 'noRestriction'],
                roles: {
                    dev: true,
                    tech: true,
                    exec: true,
                    finance: true,
                    ops: true,
                    wfm: true,
                    client: true,
                    ba: true,
                },
            },
            id: '124',
            items: [
                {
                    name: 'Sample date',
                    id: '123',
                    type: 'date',
                    status: 'open',
                    reason: null,
                    dateString: '2021-08-08',
                    date: '2021-08-08',
                    times: [
                        {
                            status: 'open',
                            reason: null,
                            id: '123',
                            startTime: '12:00:00',
                            endTime: '13:00:00'
                        },
                        {
                            status: 'closed',
                            reason: 'training',
                            id: 'drtsdargtsdfg',
                            startTime: '12:13:00',
                            endTime: '12:18:00'
                        }

                    ]
                },
                {
                    name: 'Sample date range',
                    id: '1234',
                    type: 'dateRange',
                    status: 'open',
                    reason: null,
                    dateString: '2021-08-08~2021-09-19',
                    date: ['2021-08-08','2021-09-19'],
                    days: ['Sunday'],
                    times: [
                        {
                            status: 'open',
                            reason: null,
                            id: 'gfsa',
                            startTime: '12:00:00',
                            endTime: '13:00:00'
                        },
                        {
                            status: 'closed',
                            reason: 'training',
                            id: 'wertwert',
                            startTime: '12:13:00',
                            endTime: '12:18:00'
                        }

                    ]
                },
                {
                    name: 'Sample annual date',
                    id: '12345',

                    type: 'annualDate',
                    status: 'open',
                    reason: null,
                    date: '2021-08-08',
                    dateString: 'XXXX-08-08',
                    times: [
                        {
                            status: 'open',
                            reason: null,
                            id: 'wee',
                            startTime: '12:00:00',
                            endTime: '13:00:00'
                        }
                    ]
                },
                {
                    name: 'Sample day',
                    id: '123567',

                    type: 'day',
                    days: ['Sunday'],
                    status: 'open',
                    reason: null,
                    dateString: null,
                    times: [
                        {
                            status: 'open',
                            reason: null,
                            id: 'jyh',
                            startTime: '12:00:00',
                            endTime: '13:00:00'
                        }
                    ]
                },
                {
                    name: 'Nth day',
                    id: '123sdfasdf',

                    type: 'nthDay',
                    days: ['Sunday'],
                    status: 'open',
                    reason: null,
                    dateString: 'XXXX-11-XX',
                    nth: 4,
                    times: [
                        {
                            status: 'open',
                            reason: null,
                            id: 'iuuio',
                            startTime: '12:00:00',
                            endTime: '13:00:00'
                        }
                    ]
                }

            ],
            name: 'Chanel BTQ - new york, FL - 407-352-5100',
            services: ['Chanel BTQ - Orlando, FL - 407-352-5100'],
            timezone: 'EST5EDT'
        },
    ]
}