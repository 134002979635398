<template>
  <v-select
  label="Timezone"
  :items="items"
  v-bind:value="value"
  @change="change"
  ></v-select>
</template>

<script>
export default {
    data(){
        return {
            items:[
            {text: 'EST - Eastern Standard Time (DST)', value:'EST5EDT'},
            {text: 'CST - Central Standard Time (DST)', value:'CST6CDT'},
            {text: 'MST - Mountain Standard Time (DST)', value:'MST7MDT'},
            {text: 'PST - Pacific Standard Time (DST)', value:'PST8PDT'},
            {text: 'HST- Hawaii Standard Time (DST)', value:'HST'},
        ]}
    },
    props: ['value'],
    methods:{
        change(evt){
            this.$emit('input', evt)
        }
    }
}
</script>

<style>

</style>