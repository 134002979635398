<template>
  <v-dialog
    transition="dialog-right-transition"
    max-width="800"
    v-model="dialog"
    persistent
    v-if="item"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn x-small icon  :dark="dark" v-bind="attrs" v-on="on">
        <v-icon right>mdi-calendar-edit</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary" dark
        >Edit Schedule Item: {{ item.name }}</v-toolbar
      >
      <v-card-text>
        <div class="pa-2" v-if="item">
          <v-text-field
            v-model="item.name"
            label="Name (optional)"
            :hint="item.summary ? 'Default: ' + item.summary : ''"
            :persistent-hint="!!item.summary"
          ></v-text-field>
          <v-row>
            <v-col>
              <field-phone-schedule-type v-model="item.type" />
            </v-col>
            <v-col>
              <field-date
                :options="{ range: item.type === 'dateRange', autoOpen: true }"
                v-if="showDate && item.type"
                v-model="item.date"
                :type="item.type"
              />
            </v-col>
          </v-row>

          <field-day-select v-if="showDays" v-model="item.days" />
          <field-phone-schedule-status v-model="item" noOpenReason="true" />
          <div v-if="item.status === 'open'">
            <template v-for="(time, index) in item.times">
              <div
                :key="time.id"
                :class="{ red: time.overlap, 'lighten-5': time.overlap }"
                :title="time.overlap ? 'These times overlap' : null"
                class="mb-2"
              >
                <div class="d-flex">
                  <field-time
                    :iconColor="
                      time.overlap
                        ? 'red'
                        : item.times[index].status === 'open'
                        ? 'green'
                        : null
                    "
                    v-model="item.times[index]"
                    class="flex-grow-1"
                    :timezone="timezone"
                  ></field-time>
                  <field-phone-schedule-status v-model="item.times[index]" />
                  <v-btn
                    class="mt-5"
                    @click="item.times.splice(index, 1)"
                    icon
                    color="error"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
                <v-text-field
                  :key="time.id + 'custom'"
                  label="Custom Message"
                  rows="2"
                  v-if="time.reason === 'custom'"
                  v-model.trim="time.customReason"
                  prepend-icon="mdi-phone-in-talk"
                  persistent-hint
                  hint="NOTE: A custom message MUST be setup on the backend first. It must be entered EXACTLY as it was configured on the backend"
                ></v-text-field>
                <hr class="mt-2" />
              </div>
            </template>
          </div>
          <div v-else>
            <v-text-field
              label="Custom Message"
              rows="2"
              v-if="item.reason === 'custom'"
              v-model.trim="item.customReason"
              prepend-icon="mdi-phone-in-talk"
              persistent-hint
              hint="NOTE: A custom message MUST be setup on the backend first. It must be entered EXACTLY as it was configured on the backend"
            ></v-text-field>
          </div>
          <v-row class="text--center mt-2">
            <v-btn
              color="primary"
              block
              small
              v-if="
                item.status === 'open' &&
                (!item.times || !item.times.length || validTimes) &&
                valid
              "
              @click="addTime"
              >Add Time</v-btn
            >
          </v-row>
        </div>
      </v-card-text>
      <v-alert type="warning" dense class="ml-5 mr-5" v-if="!valid && item.status">
        <span>Invalid. Ensure all fields are filled and no times overlap</span>
      </v-alert>
      <v-card-actions class="justify-end">
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn text @click="remove" color="red" v-if="item && item.id && valid"
          >Delete</v-btn
        >
        <v-btn
          text
          @click="clone"
          color="primary"
          v-if="item && item.id && valid"
          >Clone</v-btn
        >
        <v-spacer />
        <v-btn :disabled="!valid" color="success" @click="change">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FieldTime from "../fields/FieldTime.vue";
import FieldDate from "../fields/FieldDate.vue";
import FieldDaySelect from "../fields/FieldDaySelect.vue";
import FieldPhoneScheduleStatus from "../fields/FieldPhoneScheduleStatus.vue";
import FieldPhoneScheduleType from "../fields/FieldPhoneScheduleType.vue";
import { validateTimeRange, formatDays } from "../../utils/dateTimeFunctions";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    FieldPhoneScheduleType,
    FieldPhoneScheduleStatus,
    FieldDate,
    FieldTime,
    FieldDaySelect,
  },
  data() {
    return {
      dialog: false,
      item: null,
    };
  },
  created() {
    console.log(this.value, "OK??");
    this.value;
  },
  props: {"value": {type: Object},"timezone":{type:String}, dark: {type:Boolean, default:()=>false }},
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.item = val ? JSON.parse(JSON.stringify(val)) : null;
      },
    },
    "item.status"(val) {
      if (val === "closed") {
        this.item.times = [];
        if (!this.item.reason){
          this.item.reason = 'afterhours'
        }
      } else if (val === "open" && !this.item.times.length) {
        this.addTime();
      }
    },
    "item.days"() {
      this.item.summary = this.summary();
    },
    "item.date"(val) {
      if (val && this.item.type === "nthDay") {
        this.item.nth = Math.ceil(+this.$dayjs(val).format("D") / 7);
        this.item.days = [this.$dayjs(val).format("dddd")];
        this.item.month = [this.$dayjs(val).format("MMMM")];
        this.item.dateString = `XXXX-${this.$dayjs(val).format("MM")}-XX`;
      }
      this.item.summary = this.summary();
    },
    "item.type"(val) {
      let item = this.item;
      console.log(val, item);
      switch (val) {
        case "date":
          if (Array.isArray(item.date)) {
            item.date = item.date[0] || null;
          }
          item.nth = null;
          item.days = null;
          break;
        case "annualDate":
          console.log(Array.isArray(item.date));
          if (Array.isArray(item.date)) {
            item.date = item.date[0] || null;
          }
          item.nth = null;
          item.days = null;
          break;
        case "nthDay":
          if (Array.isArray(item.date)) {
            item.date = item.date[0] || null;
          }
          if (item.days && item.days.length > 1) {
            item.date.splice(0, 1);
          }
          break;
        case "dateRange":
          if (!Array.isArray(item.date)) {
            item.date = item.date ? [item.date] : [];
          }
          item.nth = null;
          break;
        case "day":
          item.date = null;
          item.nth = null;

          break;
      }
      this.item.summary = this.summary();
    },
  },
  computed: {
    noOverlappingTimes() {
      if (!this.item) {
        return true;
      }
      if (this.item.status === "closed") {
        return true;
      }
      if (this.item.times.length == 1) {
        return true;
      }
      let valid = true;
      this.item.times.forEach((time) => {
        time.overlap = false;
        let others = this.item.times.filter((item) => item.id !== time.id);
        let now = dayjs().format("YYYY-MM-DD");
        let startTime = dayjs(`${now}T${time.startTime}`);
        let endTime = dayjs(`${now}T${time.endTime}`);
        if (endTime.isBefore(startTime)) {
          endTime = endTime.add(1, "day");
        }
        others.forEach((other) => {
          let startTime2 = dayjs(`${now}T${other.startTime}`);
          let endTime2 = dayjs(`${now}T${other.endTime}`);
          if (endTime2.isBefore(startTime2)) {
            endTime2 = endTime2.add(1, "day");
          }
          console.log(
            startTime.format(),
            startTime2.format(),
            endTime2.format(),
            startTime.isBetween(startTime2, endTime2, "[)")
          );
          if (
            startTime.isBetween(startTime2, endTime2, "[)") ||
            startTime.isSame(startTime2) ||
            endTime.isSame(endTime2)
          ) {
            valid = false;
            time.overlap = true;
          }
        });
      });
      return valid;
    },
    validTimes() {
      if (!this.item) {
        return false;
      }
      if (this.item.status === "closed") {
        return true;
      }
      let valid = this.item && this.item.times && this.item.times.length > 0;
      this.item.times.forEach((time) => {
        if (!time.status) {
          console.log("Invalid status", time);
          valid = false;
        } else if (time.status === "closed" && !time.reason) {
          console.log("Invalid reason", time);
          valid = false;
        }
        if (
          !time.startTime ||
          !time.endTime ||
          !validateTimeRange(time.startTime, time.endTime)
        ) {
          console.log("Invalid time", time);
          valid = false;
        }
        if (
          time.status === "closed" &&
          time.reason === "custom" &&
          !time.customReason
        ) {
          console.log("Invalid Reason", time);

          valid = false;
        }
      });
      return valid;
    },
    valid() {
      let item = this.item;
      console.log(
        "validvalidvalidvalidvalidvalid",
        JSON.stringify(item),
        item.date
      );
      if (!item) {
        return true;
      }
      let validName = true;
      let validType = item.type && item.type.length > 0;
      let validDate =
        item.type === "dateRange"
          ? item.date && item.date.length === 2
          : item.type === "date" || item.type === "annualDate"
          ? item.date && item.date.length
          : true;
      let validDays =
        item.type === "day" ? item.days && item.days.length : true;
      let validTimes = this.validTimes;
      let validStatus =
        item.status === "open"
          ? true
          : item.status === "closed" && item.reason
          ? true
          : false;

      let validCustom =
        item.reason === "custom"
          ? item.customReason.length
            ? true
            : false
          : true;

      let validTimeCustom =
        item.times.filter(
          (item) => item.reason === "custom" && !item.customReason
        ).length === 0;

      let validOverlap = this.noOverlappingTimes;

      console.log("54354345345345345345345345", {
        name: item.name,
        validName,
        validType,
        validDate,
        validDays,
        validTimes,
        validStatus,
        validCustom,
        validTimeCustom,
        validOverlap,
        item: JSON.stringify(item),
      });
      return (
        validName &&
          validType &&
          validDate &&
          validDays &&
          validTimes &&
          validStatus &&
          validCustom &&
          validTimeCustom &&
        validOverlap
      );
    },
    showDays() {
      console.log(this.item);
      return (
        this.item &&
        (this.item.type === "day" ||
          this.item.type === "dateRange" ||
          this.item.type === "nthDay!")
      );
    },
    showDate() {
      return (
        this.item && this.item.type !== "day" && this.item.type !== "nthDay!"
      );
    },
  },
  methods: {
    summary() {
      console.log("SUMMARY", this.item);
      let message = null;

      let nth =
        this.item.nth +
        (this.item.nth == 1
          ? "st"
          : this.item.nth == 2
          ? "nd"
          : this.item.nth == 3
          ? "rd"
          : "th");
      let dateFormat = "ddd MMM Do, YYYY";
      switch (this.item.type) {
        case "date":
          if (this.item.date) {
            message = `${this.$dayjs(this.item.date).format(dateFormat)}`;
          }
          break;
        case "annualDate":
          if (this.item.date) {
            message = `${this.$dayjs(this.item.date).format(
              "MMMM Do"
            )} (Annual)`;
          }
          break;
        case "nthDay":
          if (this.item?.days?.length) {
            message = `${nth} ${this.item.days[0]} in ${this.item.month} (Annual)`;
          }
          break;
        case "dateRange":
          console.log('DATE RANGE', JSON.stringify(this.item))
          if (this.item?.date?.length === 2) {
            message = `${this.$dayjs(this.item.date[0]).format(
              dateFormat
            )} to ${this.$dayjs(this.item.date[1]).format(dateFormat)}`;
            if (this.item?.days?.length) {
              message += ` (${formatDays(this.item.days)})`;
            }
          }
          break;
        case "day":
          console.log("day");
          if (this.item?.days?.length) {
            message = formatDays(this.item.days);
          }
          break;
      }
      console.log({ message });
      return message;
    },
    sortTimes() {
      if (!this.item) {
        return;
      }
      if (this.item.times && this.item.times.length > 1) {
        this.item.times.sort((a, b) => {
          console.log("SORT", a, b);
          let date1 = dayjs()
            .hour(+a.startTime.split(":")[0])
            .minute(+a.startTime.split(":")[1])
            .second(0);
          console.log({ date1 });
          let date2 = dayjs()
            .hour(+b.startTime.split(":")[0])
            .minute(+b.startTime.split(":")[1])
            .second(0);
          console.log({ date2 });
          return date1 - date2;
        });
        console.log("SORT");
      }
      console.log("DONE SORT");
    },
    addTime() {
      if (!this.item) {
        return;
      }
      let now = this.$dayjs().format("YYYY-MM-DD");
      let times = this.item.times || [];
      let startTime =
        times.length > 0 ? times[times.length - 1].endTime : "09:00:00";
      let endTime =
        times.length == 0
          ? "17:00:00"
          : this.$dayjs(`${now}T${startTime}`)
              .add(1, "hour")
              .format("HH:mm:ss");
      this.item.times.push({
        id: uuidv4(),
        startTime,
        endTime,
        status: "open",
        reason: null,
        customReason: null,
        overlap: false,
      });
    },
    change() {
      this.sortTimes();
      if (!this.item.id) {
        this.item.id = uuidv4();
      }
      console.log(
        "CHANGE!!!!!!!!!!!!!!!!!!!!!",
        JSON.stringify(this.item, null, 2)
      );
      if (!this.item.name) {
        this.item.name = this.item.summary;
      }
      this.$emit("change", JSON.parse(JSON.stringify(this.item)));
      this.$emit("input", JSON.parse(JSON.stringify(this.item)));
      this.dialog = false;
    },
    remove() {
      this.$emit("remove");
    },
    clone() {
      this.change();
      this.$emit("clone");
    },

    cancel() {
      this.item = JSON.parse(JSON.stringify(this.value));
      console.log(this.item);
      this.dialog = false;
      if (!this.item.id) {
        this.remove();
      }
    },
  },
  mounted() {
    if (!this.item.type) {
      this.dialog = true;
    }
  },
};
</script>

<style>
.break {
  flex-basis: 100%;
  height: 0;
}
</style>