var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('v-dialog',{attrs:{"transition":"dialog-right-transition","max-width":"800","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":"","dark":_vm.dark}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-calendar-edit")])],1)]}}],null,false,3098318464),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Edit Schedule Item: "+_vm._s(_vm.item.name))]),_c('v-card-text',[(_vm.item)?_c('div',{staticClass:"pa-2"},[_c('v-text-field',{attrs:{"label":"Name (optional)","hint":_vm.item.summary ? 'Default: ' + _vm.item.summary : '',"persistent-hint":!!_vm.item.summary},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}}),_c('v-row',[_c('v-col',[_c('field-phone-schedule-type',{model:{value:(_vm.item.type),callback:function ($$v) {_vm.$set(_vm.item, "type", $$v)},expression:"item.type"}})],1),_c('v-col',[(_vm.showDate && _vm.item.type)?_c('field-date',{attrs:{"options":{ range: _vm.item.type === 'dateRange', autoOpen: true },"type":_vm.item.type},model:{value:(_vm.item.date),callback:function ($$v) {_vm.$set(_vm.item, "date", $$v)},expression:"item.date"}}):_vm._e()],1)],1),(_vm.showDays)?_c('field-day-select',{model:{value:(_vm.item.days),callback:function ($$v) {_vm.$set(_vm.item, "days", $$v)},expression:"item.days"}}):_vm._e(),_c('field-phone-schedule-status',{attrs:{"noOpenReason":"true"},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}}),(_vm.item.status === 'open')?_c('div',[_vm._l((_vm.item.times),function(time,index){return [_c('div',{key:time.id,staticClass:"mb-2",class:{ red: time.overlap, 'lighten-5': time.overlap },attrs:{"title":time.overlap ? 'These times overlap' : null}},[_c('div',{staticClass:"d-flex"},[_c('field-time',{staticClass:"flex-grow-1",attrs:{"iconColor":time.overlap
                      ? 'red'
                      : _vm.item.times[index].status === 'open'
                      ? 'green'
                      : null,"timezone":_vm.timezone},model:{value:(_vm.item.times[index]),callback:function ($$v) {_vm.$set(_vm.item.times, index, $$v)},expression:"item.times[index]"}}),_c('field-phone-schedule-status',{model:{value:(_vm.item.times[index]),callback:function ($$v) {_vm.$set(_vm.item.times, index, $$v)},expression:"item.times[index]"}}),_c('v-btn',{staticClass:"mt-5",attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.item.times.splice(index, 1)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),(time.reason === 'custom')?_c('v-text-field',{key:time.id + 'custom',attrs:{"label":"Custom Message","rows":"2","prepend-icon":"mdi-phone-in-talk","persistent-hint":"","hint":"NOTE: A custom message MUST be setup on the backend first. It must be entered EXACTLY as it was configured on the backend"},model:{value:(time.customReason),callback:function ($$v) {_vm.$set(time, "customReason", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"time.customReason"}}):_vm._e(),_c('hr',{staticClass:"mt-2"})],1)]})],2):_c('div',[(_vm.item.reason === 'custom')?_c('v-text-field',{attrs:{"label":"Custom Message","rows":"2","prepend-icon":"mdi-phone-in-talk","persistent-hint":"","hint":"NOTE: A custom message MUST be setup on the backend first. It must be entered EXACTLY as it was configured on the backend"},model:{value:(_vm.item.customReason),callback:function ($$v) {_vm.$set(_vm.item, "customReason", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.customReason"}}):_vm._e()],1),_c('v-row',{staticClass:"text--center mt-2"},[(
              _vm.item.status === 'open' &&
              (!_vm.item.times || !_vm.item.times.length || _vm.validTimes) &&
              _vm.valid
            )?_c('v-btn',{attrs:{"color":"primary","block":"","small":""},on:{"click":_vm.addTime}},[_vm._v("Add Time")]):_vm._e()],1)],1):_vm._e()]),(!_vm.valid && _vm.item.status)?_c('v-alert',{staticClass:"ml-5 mr-5",attrs:{"type":"warning","dense":""}},[_c('span',[_vm._v("Invalid. Ensure all fields are filled and no times overlap")])]):_vm._e(),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")]),(_vm.item && _vm.item.id && _vm.valid)?_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.remove}},[_vm._v("Delete")]):_vm._e(),(_vm.item && _vm.item.id && _vm.valid)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.clone}},[_vm._v("Clone")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.valid,"color":"success"},on:{"click":_vm.change}},[_vm._v("Save")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }