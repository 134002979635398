<template>
  <div class="flex-grow-1 d-flex" style="max-height: calc(100vh - 80px)">
    <v-card class="flex-grow-0 mr-2" v-if="!$route.params.id || openMenu">
      <v-card-text>
        <v-text-field
          autofocus
          clearable
          v-model="searchSchedules"
          dense
          placeholder="Search Schedules"
        ></v-text-field>
        <v-list
          dense
          style="max-height: calc(100vh - 200px)"
          class="overflow-y-auto"
        >
          <template v-for="schedule in filteredSchedules">
            <v-list-item
              :style="{ height: 'auto' }"
              :key="schedule.id"
              class="ml-2"
              :to="`/phone-schedules/${schedule.id}`"
              :disabled="changed"
              @click="openMenu = false"
            >
              {{ schedule.name }}
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
    <v-card class="flex-grow-1" style="overflow-y:auto;">
      <v-app-bar dense dark class="">
        <template>
          <v-btn
            @click="openMenu = true"
            dark
            icon
            :title="
              changed ? 'Disabled until saved or changes discarded' : null
            "
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>

          <v-app-bar-title v-if="selectedSchedule">
            {{ selectedSchedule.name }}
          </v-app-bar-title>
          <v-spacer></v-spacer>
          <template v-if="selectedSchedule">
            <div class="text-caption">
              {{ selectedSchedule.services.join(", ") }}
            </div>
            <div class="pl-2 pr-2">
              <v-icon small>mdi-clock-outline</v-icon>
            </div>
            <div class="text-caption">({{ selectedSchedule.timezone }})</div>
            <div class="pl-3">
              <edit-phone-schedule
                v-model="selectedSchedule"
                @change="updateScheduleParams"
              />
            </div>
          </template>
        </template>
      </v-app-bar>
      <v-card-text>

      <v-alert
        :type="changeAlert.type"
        class="ma-4 miniPulse"
        v-if="changeAlert && !changed"
      >
        {{ changeAlert.message }}
      </v-alert>

      <v-alert type="warning" class="ma-4 miniPulse" v-if="changed">
        You have made changes. These will not take affect until you PUBLISH.
        Navigating away will result in lost changes.
      </v-alert>
        <v-card v-if="selectedSchedule">
          <v-simple-table dense class="striped" fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" width="250px">Active</th>
                  <th class="text-left">Name</th>
                  <th class="text-left">When</th>
                  <th class="text-left">
                    Hours ({{ selectedSchedule.timezone }})
                  </th>
                  <th class="text-left" width="100px">
                    <span class="ml-2">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :title="
                    checked && checked.rule.id == item.id
                      ? testedScheduleMessage
                      : null
                  "
                  v-for="item in selectedSchedule.items"
                  :key="item.id"
                  :class="{
                    active: checked.found && checked.rule.id === item.id,
                    expired:
                      (item.type == 'date' || item.type == 'dateRange') &&
                      beforeNow(item.date),
                    red:
                      checked &&
                      checked.rule.id == item.id &&
                      checked.status == 'closed',
                    green:
                      checked &&
                      checked.rule.id == item.id &&
                      checked.status == 'open',
                    'white--text': checked && checked.rule.id == item.id,
                    'ligthen--text': checked && checked.rule.id == item.id,
                    'lighten-2': checked && checked.rule.id == item.id,
                  }"
                >
                  <td>
                    <div
                      v-if="
                        (item.type == 'date' || item.type == 'dateRange') &&
                        beforeNow(item.date)
                      "
                    >
                      Expired - Please Remove
                    </div>
                    <!--<div
                    v-if="
                      checkMatchedSchedule(item).date"
                    
                  >
                     <div>
                      <v-icon title="Today" small color="green"
                        >mdi-calendar</v-icon
                      >
                      <v-icon
                        title="Now"
                        small
                        :color="isCurrent(item).time ? 'green' : 'red'"
                        >mdi-alarm-check</v-icon
                      >
                      <v-icon
                        v-if="isCurrent(item).status === 'closed'"
                        title="Closed"
                        small
                        color="red"
                        >mdi-phone-off</v-icon
                      >
                      <v-icon v-else title="Open" small color="green"
                        >mdi-phone-incoming</v-icon
                      >
                    </div>
                    <div v-if="checked.reason">
                      ({{ text[checked.reason] }})
                    </div>
                  </div> -->

                    <!-- <div
                    v-if="
                      checked.found &&
                      checked.rule.id === item.id
                    "
                  > 
                   <div>
                      <v-icon :title="$dayjs(checked.nowTz).format('YYYY-MM-DD')" color="green"
                        >mdi-calendar</v-icon
                      >
                      <v-icon
                        :title="$dayjs(checked.nowTz).format('h:mma')"
                        small
                        color="green"
                        >mdi-alarm-check</v-icon
                      >
                      <v-icon
                        v-if="isCurrent(item).status === 'closed'"
                        title="Closed"
                        small
                        color="red"
                        >mdi-phone-off</v-icon
                      >
                      <v-icon v-else title="Open" small color="green"
                        >mdi-phone-incoming</v-icon
                      >
                    </div>
                  </div> -->
                    <div v-if="checked && checked.rule.id === item.id">
                      <span>{{ text[checked.status] }}</span>
                      <span v-if="text[checked.reason]">
                        - ({{ text[checked.reason] }}</span
                      >
                      <span v-if="checked.customReason"
                        >: {{ checked.customReason }}</span
                      >
                      <span v-if="text[checked.reason]">)</span>
                      <span class="mr-4"></span>

                      <v-icon :title="checked.testDate" small dark
                        >mdi-calendar</v-icon
                      >
                      <v-icon :title="checked.testTime" small dark
                        >mdi-alarm-check</v-icon
                      >
                      <v-icon
                        :title="
                          (checked.status === 'open' ? 'Open' : 'Closed') +
                          ' ' +
                          `${checked.reason ? text[checked.reason] : ''} ${
                            checked.customReason || ''
                          }`
                        "
                        small
                        dark
                        >{{
                          checked.status === "open"
                            ? "mdi-phone-incoming"
                            : "mdi-phone-off"
                        }}</v-icon
                      >
                    </div>
                    <!-- <div
                    v-if="
                      matchedSchedule &&
                      item &&
                      matchedSchedule.itemId == item.id
                    "
                  >
                  {{matchedSchedule}}

                    <div>
                      <span>{{ text[matchedSchedule.status] }}</span>
                      <span v-if="text[matchedSchedule.reason]">
                        - ({{ text[matchedSchedule.reason] }}</span
                      >
                      <span v-if="matchedSchedule.customReason"
                        >: {{ matchedSchedule.customReason }}</span
                      >
                      <span v-if="text[matchedSchedule.reason]">)</span>
                      <span class="mr-4"></span>

                      <v-icon :title="matchedSchedule.testDate" small dark
                        >mdi-calendar</v-icon
                      >
                      <v-icon :title="matchedSchedule.testTime" small dark
                        >mdi-alarm-check</v-icon
                      >
                      <v-icon
                        :title="
                          (matchedSchedule.status === 'open'
                            ? 'Open'
                            : 'Closed') +
                          ' ' +
                          `${
                            matchedSchedule.reason
                              ? text[matchedSchedule.reason]
                              : ''
                          } ${matchedSchedule.customReason || ''}`
                        "
                        small
                        dark
                        >{{
                          matchedSchedule.status === "open"
                            ? "mdi-phone-incoming"
                            : "mdi-phone-off"
                        }}</v-icon
                      >
                    </div>
                  </div> -->
                  </td>

                  <td>
                    {{ item.name }} <em> ({{ text[item.type] }})</em>
                  </td>
                  <td>
                    {{ item.summary }}
                    <!-- <span v-if="item.date"></span>
                  <span v-if="item.type == 'annualDate'">{{
                    item.date | annualDate
                  }}</span>
                  <span v-else>{{ item.date | date }}</span>
                  <span v-if="item.days">
                    <span v-if="item.date || item.nth">(</span>
                    <span v-if="item.date"> Only</span>
                    <span v-if="item.nth">
                      {{item.nth}}<sup>{{item.nth == 1 ? 'st' : item.nth == 1 ? 'nd' : item.nth == 1 ? 'rd' : 'th'}}</sup>&nbsp;
                    </span>
                     
                    <span v-if="item.nth">{{ item.days[0]}}</span> 
                    <span v-else-if="item.days">{{ item.days | day}}</span> 
                    <span v-if="item.date || item.nth">) </span>
                  </span> -->
                  </td>
                  <td
                    :class="{
                      active: checked.found && checked.rule.id === item.id,
                    }"
                  >
                    <span v-if="item.status == 'closed'">
                      <v-icon
                        small
                        :dark="checked && checked.rule.id === item.id"
                        class="mr-2"
                        >mdi-phone-off</v-icon
                      >
                      Closed ({{ text[item.reason] }})
                    </span>
                    <div
                      v-else
                      v-for="time in item.times"
                      :key="time.startTime"
                    >
                      <v-icon v-if="time.status === 'closed'" small class="mr-2"
                        >mdi-phone-off</v-icon
                      >
                      <v-icon
                        v-else
                        small
                        class="mr-2"
                        :dark="checked && checked.rule.id === item.id"
                        >mdi-phone-incoming</v-icon
                      >
                      <span
                        v-if="
                          time.startTime === '00:00:00' &&
                          time.endTime === '00:00:00'
                        "
                        >All Day</span
                      >
                      <span v-else
                        >{{ time.startTime | time }} -
                        {{ time.endTime | time }}</span
                      >
                      <!-- <span>{{ text[time.status] }}</span> -->
                      (<span v-if="time.status">{{ text[time.status] }}</span>
                      <span v-if="time.reason">
                        - {{ text[time.reason] }} {{ time.customReason }}</span
                      >)
                    </div>
                  </td>
                  <td>
                    <v-btn
                      icon
                      v-if="
                        (item.type == 'date' || item.type == 'dateRange') &&
                        beforeNow(item.date)
                      "
                      @click="removeItem(item)"
                    >
                      <v-icon color="red"> mdi-delete </v-icon></v-btn
                    >
                    <EditPhoneScheduleItem
                      v-model="
                        selectedSchedule.items.filter((i) => i.id == item.id)[0]
                      "
                      :timezone="selectedSchedule.timezone"
                      :dark="checked && checked.rule.id === item.id"
                      @remove="removeItem(item)"
                      @change="changedItem"
                    />
                  </td>
                </tr>
                <tr
                  :class="{ active: !checked.found }"
                  v-if="!checked.found"
                  class="red lighten-2 white--text"
                  :title="testedScheduleMessage"
                >
                  <td>
                    Closed - (Afterhours)

                    <span class="mr-4"></span>

                    <v-icon :title="checked.testDate" small dark
                      >mdi-calendar</v-icon
                    >
                    <v-icon :title="checked.testTime" small dark
                      >mdi-alarm-check</v-icon
                    >
                    <v-icon
                      :title="
                        (checked.status === 'open' ? 'Open' : 'Closed') +
                        ' ' +
                        `${checked.reason ? text[checked.reason] : ''} ${
                          checked.customReason || ''
                        }`
                      "
                      small
                      dark
                      >{{
                        checked.status === "open"
                          ? "mdi-phone-incoming"
                          : "mdi-phone-off"
                      }}</v-icon
                    >
                  </td>
                  <td>Closed - No Match</td>
                  <td>Assumed Closed since there are no matches above</td>
                  <td>
                    <v-icon small color="red">mdi-phone-off</v-icon> All Day
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td colspan="5" class="text-right pt-2 pb-2">
                    <v-btn @click="newItem" class="primary ml-5" small
                      ><v-icon small>mdi-calendar-plus</v-icon
                      ><span> Add Rule</span></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-row class="mt-2 pa-2 pr-5 pl-5">
            <v-btn v-if="changed" @click="revert" color="secondary"
              ><v-icon>mdi-history</v-icon>Rollback Changes</v-btn
            >
            <v-spacer></v-spacer>
            <CheckPhoneSchedule v-model="testTime" />
            <v-btn
              color="success"
              class="ml-5 wobble animated infinite"
              @click="publish"
              v-show="changed"
              ><v-icon>mdi-save</v-icon>Publish</v-btn
            >
          </v-row>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-alert type="info" class="ml-4 mr-4">
        <div class="d-flex">
          <span>{{ testedScheduleMessage }}</span>
          <v-spacer />
          <v-btn
            v-if="testTime"
            @click="resetTestTime"
            color="seconary"
            small
            dark
            >Reset Test</v-btn
          >
        </div>
      </v-alert>
      <!-- <div v-if="testTime">
      This schedule will be {{ checked.status }} on {{ testTime | date }} at
      {{ testTime | time }} {{ getTimeZone(testTime) }}
    </div> -->
    </v-card>
  </div>
</template>

<script>
import clone from "../utils/clone";
import {
  formatDays,
  beforeNow,
  isCurrent,
  getTimeZone,
  checkMatchedSchedule,
} from "../utils/dateTimeFunctions";
// import { v4 as uuidv4 } from "uuid";
// import sampleDB from "../sampleDB";
import EditPhoneSchedule from "../components/modals/EditPhoneSchedule.vue";
import EditPhoneScheduleItem from "../components/modals/EditPhoneScheduleItem.vue";
import checkHours from "../utils/checkHours";
import CheckPhoneSchedule from "../components/modals/CheckPhoneSchedule.vue";
import firebase from "../firebase";
import { mapState } from "vuex";
const db = firebase.firestore();
export default {
  components: {
    EditPhoneSchedule,
    EditPhoneScheduleItem,
    CheckPhoneSchedule,
  },
  data() {
    return {
      openMenu: false,
      searchSchedules: "",
      testTime: null,
      selectedScheduleId: null,
      selectedSchedule: null,
      checked: {},
      changeAlert: null,
      changeAlertTimer: null,
      text: {
        date: "Date",
        dateRange: "Date Range",
        annualDate: "Annual Date",
        day: "Day",
        nthDay: "nth Day",
        open: "Open",
        closed: "Closed",
        holiday: "Holiday",
        special: "Special",
        training: "Training",
        outage: "Outage",
        custom: "Custom",
        postOutage: "Post Outage",
        noPhones: "No Phones",
        afterhours: "Afterhours",
      },
    };
  },
  watch: {
    changeAlert(val) {
      if (val) {
        clearTimeout(this.changeAlertTimer);
        let time = val.type === "success" ? 5 : 30;
        this.changeAlertTimer = setTimeout(() => {
          this.changeAlert = null;
        }, time * 1000);
      }
    },
    originalSchedule(val, oldVal) {
      console.log("-------------------------------------------------");
      let changedByMe =
        JSON.stringify(val) === JSON.stringify(this.selectedSchedule);
      console.log(
        JSON.stringify(val) === JSON.stringify(this.selectSchedule),
        JSON.stringify(val),
        JSON.stringify(this.selectSchedule)
      );
      if (val !== oldVal) {
        this.revert();
      }
      if (val && oldVal) {
        if (changedByMe) {
          this.changeAlert = {
            type: "success",
            message: "Changes Saved and Active",
          };
        } else {
          this.changeAlert = {
            type: "error",
            message: `Someone has changed the schedule. Any changes made here have been lost`,
          };
        }
      }
    },
    testTime(val) {
      console.log("testTime", val);
      let dateTime = val?.dateTime || undefined;
      let timezone = val?.timezone || undefined;
      this.checked = checkHours(this.selectedSchedule, dateTime, timezone);
    },
    selectedSchedule: {
      deep: true,
      handler(val) {
        console.log("SELECTED CHANGED");
        this.checked = checkHours(val);
        console.log("checkHours", this.checked);
      },
    },
    selectedScheduleId(val) {
      if (!val) {
        this.selectedSchedule = null;
      } else {
        this.selectedSchedule = clone(
          this.phoneSchedules.filter((schedule) => schedule.id === val)[0]
        );
        let route = this.$route;
        if (val && val !== route.params.id) {
          route.params.id = val || null;
          this.$router.push(route);
        }
      }
    },
    "$route.params.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.selectedScheduleId = val;
        } else {
          if (this.phoneSchedules.length === 1) {
            this.$route.params.id = this.phoneSchedules[0].id;
            this.$router.replace(this.$route);
          } else {
            this.openMenu = true;
          }
        }
      },
    },
  },
  computed: {
    ...mapState(["phoneSchedules"]),
    filteredSchedules() {
      if (!this.searchSchedules) {
        return this.clonedSchedules || [];
      }
      console.log(this.searchSchedules);
      return (this.clonedSchedules || []).filter((schedule) =>
        schedule.name.toLowerCase().match(this.searchSchedules)
      );
    },
    testedScheduleMessage() {
      console.log("TEST TIME", this.testTime);
      let date = this.$dayjs(this.testTime?.dateTime).format("dddd, MMM D");
      let dateTime = (this.testTime?.dateTime || "").substring(0, 19);
      if (dateTime) {
        dateTime += this.$dayjs().format("Z");
      }
      console.log({ dateTime });
      let time = this.$dayjs(dateTime || undefined).format("h:mma");
      let localTime = this.$dayjs(this.testTime?.dateTime || undefined).format(
        "h:mma"
      );
      let timezone = this.testTime?.timezone || this.$dayjs().format("zzz");
      // let date = this.$dayjs(this.testTime || undefined).format('dddd, MMM D')
      // let timezone = this.testTime ? this.testTime.substr(this.testTime.length - 6) : this.$dayjs().format('Z')
      // let utcOffset = +timezone.split(':')[0]*60 + +timezone.split(':')[1]
      // console.log({timezone},utcOffset)
      // if (this.testTime){
      //   console.log('&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&')
      //   console.log('this.testTime', this.testTime)
      //   console.log('this.testTime', this.$dayjs(this.testTime).format('zzz'))

      //   console.log(this.$dayjs(this.testTime).local().format('h:mmaZ'))
      //   console.log({timezone})
      // }
      console.log("------", this.matchedSchedule);
      let message = "";
      if (!this.matchedSchedule) {
        message = `There is no matching rules so this line will be treaded as CLOSED on ${date} at ${time} (${timezone})`;
      } else if (this.matchedSchedule.now) {
        message = `This schedule is currently ${this.matchedSchedule?.status?.toUpperCase()} as of ${date} at ${time} (${timezone})`;
      } else {
        message = `This schedule will be ${this.matchedSchedule.status.toUpperCase()} on ${date} at ${time} (${timezone})`;
      }
      if (localTime !== time) {
        message += ` - ${localTime} your local time.`;
      }
      if (this.testTime) {
        let ago = this.$dayjs(this.testTime?.dateTime).fromNow();
        message += ` - ${ago}`;
      }
      return message;
    },
    matchedSchedule() {
      let testTime = this.testTime;
      console.log({ testTime });
      let matched = (this.selectedSchedule?.items || [])
        .map((item) => {
          let match = checkMatchedSchedule(
            item,
            testTime?.dateTime || undefined
          );
          // console.log({ match });
          // if (!match.date) {
          //   match = checkMatchedSchedule(item);
          // }
          return match;
        })
        .filter((item) => item.date)[0];

      if (!matched && !testTime) {
        console.log(
          "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!CHECK FOR TODAY"
        );
        matched = (this.selectedSchedule?.items || [])
          .map((item) => {
            let match = checkMatchedSchedule(item);
            return match;
          })
          .filter((item) => item.date)[0];
      }
      console.log("RETURNING ");

      return matched;
    },
    clonedSchedules() {
      return clone(this.phoneSchedules);
    },
    originalSchedule() {
      console.log("ORIGINAL CHANGED");
      return this.selectedScheduleId
        ? this.phoneSchedules.filter(
            (schedule) => schedule.id === this.selectedScheduleId
          )[0]
        : null;
    },
    changed() {
      return (
        JSON.stringify(this.selectedSchedule) !==
        JSON.stringify(this.originalSchedule)
      );
    },
    valid() {
      return true;
    },
  },
  methods: {
    updateScheduleParams(schedule) {
      console.log("updateScheduleParamsupdateScheduleParams", { schedule });
    },
    changedItem(item) {
      console.log({ item }, "CHAGE");
      this.selectedSchedule.items = this.selectedSchedule.items.filter(
        (i) => i.id && i.id !== item.id
      );
      this.selectedSchedule.items.push(item);
      this.sortRules();
    },
    sortRules() {
      console.log("SORRRRRRRRRRRRT");
      if (!this.selectedSchedule?.items) {
        return [];
      }
      let dates = this.selectedSchedule.items.filter(
        (item) => item.type === "date"
      );
      let annualDate = this.selectedSchedule.items.filter(
        (item) => item.type === "annualDate"
      );
      let nthDay = this.selectedSchedule.items.filter(
        (item) => item.type === "nthDay"
      );
      let dateRange = this.selectedSchedule.items.filter(
        (item) => item.type === "dateRange"
      );
      let days = this.selectedSchedule.items.filter(
        (item) => item.type === "day"
      );
      this.selectedSchedule.items = [].concat(
        dates,
        annualDate,
        nthDay,
        dateRange,
        days
      );
    },
    publish() {
      db.collection("phone_schedules")
        .doc(this.selectedScheduleId)
        .set(this.selectedSchedule);
    },
    resetTestTime() {
      this.testTime = null;
    },
    newItem() {
      this.selectedSchedule.items.push({
        id: null,
        name: null,
        type: null,
        date: null,
        month: null,
        days: null,
        status: null,
        reason: null,
        customReason: null,
        dateString: null,
        times: [],
        summary: null,
      });
    },

    selectSchedule(val) {
      console.log(88888888888888, val);
      return JSON.parse(JSON.stringify(val));
    },
    checkMatchedSchedule,
    formatDays,
    beforeNow,
    isCurrent,
    getTimeZone,
    revert() {
      this.selectedSchedule = clone(this.originalSchedule);
    },
    removeItem(item) {
      this.selectedSchedule.items = this.selectedSchedule.items.filter(
        (i) => i.id !== item.id
      );
    },
  },
};
</script>

<style>
tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}
tr.expired {
  opacity: 0.5;
}
tr.expired td:not(:last-child, :first-child) {
  text-decoration: line-through;
}
.active {
  font-weight: bold;
}
.pulse {
  animation: pulse 1s infinite;
}

.miniPulse {
  animation: miniPulse 1s infinite;
}

@keyframes miniPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }

  70% {
    box-shadow: 0 0 40px 50px rgba(0, 0, 0, 0);
    border-radius: 0;
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }

  70% {
    transform: scale(0.9);
    box-shadow: 0 0 40px 50px rgba(0, 0, 0, 0);
    border-radius: 0;
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.animated {
  animation-duration: 3s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

/*the animation definition*/

@keyframes wobble {
  0% {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  5% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  10% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  15% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  20% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  25% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  30% {
    transform: none;
  }
  100% {
    transform: none;
  }
}

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}
</style>