<template>
  <v-select
    label="Days"
    :items="items"
    v-bind:value="value"
    @change="change"
    multiple
                prepend-icon="mdi-calendar-multiselect"

  >
    <template v-slot:selection="{ item, index }">
      <span v-if="index == 0 && displayName">{{ displayName }}</span>
      <span v-else-if="!displayName">
        <span>{{ item }}</span>
        <span v-if="index < value.length - 1">,&nbsp;</span>
      </span>
      <span v-else></span>
      
    </template>
  </v-select>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          header: "Quick Select",
        },
        {
          text: "Weekdays",
          value: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        },
        { text: "Weekends", value: ["Saturday", "Sunday"] },
        {
          text: "Daily",
          value: [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ],
        },
        {
          text: "Mon-Sat",
          value: [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ],
        },
        {
          divider: true,
        },

        {
          header: "Days",
        },
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
    };
  },
  props: ["value"],
  computed: {
    displayName() {
      let days = this.value.join(",");
      switch (days) {
        case "Monday,Tuesday,Wednesday,Thursday,Friday":
          return "Weekdays";
        case "Saturday,Sunday":
          return "Weekends";
        case "Monday,Tuesday,Wednesday,Thursday,Friday,Saturday":
          return "Mon-Sat";
        case "Monday,Tuesday,Wednesday,Thursday,Friday,Saturday,Sunday":
          return "Daily";
      }
      return null;
    },
  },
  methods: {
    change(evt) {
      if (typeof evt[evt.length - 1] === "object") {
        this.$emit("input", evt[evt.length - 1]);
      } else {
        this.$emit("input", evt);
      }

      //   let value = evt;
      //   if (evt.indexOf("Weekdays") > -1) {
      //     value = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
      //   } else if (evt.indexOf("Weekends") > -1) {
      //     value = ["Saturday", "Sunday"];
      //   } else if (evt.indexOf("Daily") > -1) {
      //     value = [
      //       "Monday",
      //       "Tuesday",
      //       "Wednesday",
      //       "Thursday",
      //       "Friday",
      //       "Saturday",
      //       "Sunday",
      //     ];
      //   } else if (evt.indexOf("Mon-Sat") > -1) {
      //     value = [
      //       "Monday",
      //       "Tuesday",
      //       "Wednesday",
      //       "Thursday",
      //       "Friday",
      //       "Saturday",
      //     ];
      //   } else {
      //     value = value.filter((item) => {
      //       return ["Weekdays", "Weekends", "Daily", "Mon-Sat"].indexOf(item) < 0;
      //     });
      //   }
      //   this.$emit("input", evt);
    },
  },
};
</script>

<style>
</style>