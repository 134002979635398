<template>
  <div class="d-flex">
    <v-text-field
      v-model="startTime"
      label="Start Time"
      type="time"
      :hint="timezone"
      persistent-hint
      :rules="[validateEndTime]"
      :error-messages="error"
      @change="change"
      step="300"

    >
      <template v-slot:prepend>
        <v-icon :color="iconColor">mdi-clock</v-icon>
      </template>
    </v-text-field>
    <v-icon>mdi-arrow-right</v-icon>
    <v-text-field
      v-model="endTime"
      label="End Time"
      :rules="[validateEndTime]"
      type="time"
      :hint="timezone"
      persistent-hint
      step="300"
      :error-messages="error"
      @change="change"
    ></v-text-field>
  </div>
</template>

<script>
import clone from "../../utils/clone";
import {validateTimeRange} from "../../utils/dateTimeFunctions";
import dayjs from 'dayjs';
export default {
  data() {
    return {
      startTime: null,
      endTime: null,
      error: null,
    };
  },
  props: ["value", "range", "timezone", "iconColor"],
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        console.log('WATCH',val);
        if (val) {
          this.startTime = val.startTime || null;
          this.endTime = val.endTime || null;
        }
        if (val.startTime && !val.endTime){
          this.endTime = val.startTime
        }
      },
    },
  },
  methods: {
    validateEndTime() {
      if (this.endTime == 'Invalid Date') this.endTime == null
      if (this.startTime && this.endTime == null){
        let endTimeParts = (this.startTime || '').split(':')
        let endTime = dayjs().hour(+endTimeParts[0]).minute(+endTimeParts[1]).second(0).add(1,'hour').format('HH:mm:ss')       
         this.endTime = endTime
      } else {
        console.log(this.startTime, this.endTime)
      }
      let valid = validateTimeRange(this.startTime, this.endTime);
      this.error = valid ? null : "Start after End";
      return true;
    },
    cancel() {
      this.date = clone(this.value);
      this.change();
    },
    change() {
      if (!this.startTime){
        return
      }
        let startTimeParts = (this.startTime || '').split(':')
        // if (startTimeParts.length !==2){
        //   return console.log('OK NO TIME') 
        // }
        let endTimeParts = (this.endTime || '').split(':')
        let endTime = null
        let startTime = dayjs().hour(+startTimeParts[0]).minute(+startTimeParts[1]).second(0).format('HH:mm:ss')
        if (this.endTime){
          endTime = dayjs().hour(+endTimeParts[0]).minute(+endTimeParts[1]).second(0).format('HH:mm:ss')

        }
      this.$emit("input", {
        ...this.value,
        ...{ startTime, endTime },
      });
    },
  },
};
</script>

<style>
</style>