<template>
  <div class="v-flex">
    <v-autocomplete
      v-bind:value="selected"
      :items="services"
      label="Services"
      multiple
      clearable
      open-on-clear
      small-chips
      placeholder="Services"
      no-data-text="No Services Available"
      deletable-chips
      @change="change"
      :allow-overflow="false"
      :search-input.sync="search"
    >
      <template v-slot:prepend-item>
        <v-list-item ripple @click="toggleAll">
          <v-list-item-action>
            <v-icon v-if="allSelected"> mdi-checkbox-multiple-marked </v-icon>
            <v-icon v-else> mdi-checkbox-multiple-marked-outline </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-if="allSelected">
              Select None
            </v-list-item-title>
            <v-list-item-title v-else> Select All </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
      <template v-slot:selection="{ item, index }">
        <span v-if="index === 0">
          <span>{{ item.text }}</span>
        </span>
        <span v-if="index === 1" class="grey--text text-caption">
          (+{{ selected.length - 1 }} other{{ selected.length > 1 ? "s" : "" }})
        </span>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import sampleDB from "../../sampleDB";
export default {
  data() {
    return {
      model: false,
      search: "",
      selected: [],
      items: sampleDB.clients[0],
    };
  },
  props: ["value", "options"],
  computed: {
    allSelected() {
      return this.selected && this.selected.length === this.services.length;
    },
    services() {
      let services = [];
      sampleDB.clients.forEach((client) => {
        client.brightPattern.services.forEach((service) => {
          let item = {
            text: `${client.name} - ${service}`,
            value: service,
          };
          services.push(item);
        });
      });
      return services;
    },
  },
  // watch:{
  //   value:{
  //     immediate: true,
  //     handler(val){
  //       if (val && this.options){
  //       //   Object.keys(val).forEach(fieldID=>{
  //       //     console.log(fieldID)
  //       //     if ( val[fieldID]){
  //       //     let field = val[fieldID]
  //       console.log('INIT', {val})
  //           if (val && this.options.key && val[this.options.key]){
  //               this.selected = val[this.options.key]
  //              }
  //         //   }
  //         // })
  //       }

  //     }
  //   }
  // },
  mounted() {
    this.$nextTick(() => {
      if (this.value) {
        this.selected = this.options?.key
          ? this.value[this.options.key]
          : this.value;
      }
    });
  },
  methods: {
    toggleAll() {
      if (this.allSelected) {
        this.selected = [];
      } else {
        this.selected = this.services
          .filter((item) => {
            return this.search
              ? item.value.toLowerCase().match(this.search.toLowerCase())
              : true;
          })
          .map((service) => service.value);
      }
      let result = this.selected;

      if (this.options?.key) {
        result = { [this.options.key]: this.selected };
      }
      this.$emit("change", result);
      this.$emit("input", result);
    },
    change(evt) {
      this.selected = evt;
      let result = this.selected;

      if (this.options?.key) {
        result = { [this.options.key]: this.selected };
      }
      this.$emit("change", result);
      console.log('EMEIT', result)
      this.$emit("input", result);
    },
  },
};
</script>

<style>
</style>