import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
var advancedFormat = require('dayjs/plugin/advancedFormat')
const isBetween = require("dayjs/plugin/isBetween")
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(isBetween)

const checkHours = (schedule, testDateTime, testTimezone)=>{
    let now = dayjs(testDateTime)
    let timeZone = schedule.timezone || "EST5EDT"
    console.log(schedule)
    let dates = schedule.items.filter((item) => item.type == "date")
    let annualDates = schedule.items.filter((item) => item.type == "annualDate")
    let nthDays = schedule.items.filter((item) => item.type == "nthDay")
    let dateRanges = schedule.items.filter((item) => item.type == "dateRange")
    let days = schedule.items.filter((item) => item.type == "day")
  
    let date = dates.filter((item) =>  now.format("YYYY-MM-DD", { timeZone }) === item.date)[0]
  
    let annualDate = annualDates.filter(
      (item) =>{
          let format = "MM-DD"
          return  now.format(format, { timeZone }) === dayjs(item.date).format(format)
      }
    )[0]

    let nthDay = nthDays.filter(
      (item) => {
        let dayNumber = Math.ceil(+(now.format("DD", { timeZone }) / 7))
        console.log({})
        return dayNumber === +item.nth
         && now.format("MMMM", { timeZone }) == item.month
          && (!item.days || item.days.indexOf(now.format("dddd", { timeZone })) > -1)
        })[0]
  
    let dateRange = dateRanges.filter((item) => {
      let start = dayjs(`${item.date[0]}T00:00:00`, { timeZone })
      let end = dayjs(`${item.date[1]}T00:00:00`, { timeZone })
  
      return (now.isBetween(start, end, "day", "[]") &&
        (!item.days || !item.days.length || item.days.indexOf(now.format("dddd", { timeZone })) > -1)
      )
    })[0]
  
    let day = days.filter(
      (item) =>(!item.days || !item.days.length || item.days.indexOf(now.format("dddd", { timeZone })) > -1)
    )[0]
  
    console.log({date,annualDate,nthDay, dateRange, day})
    let result = date || annualDate || nthDay || dateRange || day
   
    if (!result || result.status ==='closed') {
      return {
        found: result ? true:false,
        status: 'closed',
        hours:null,
        reason: result ? result.reason || null : null,
        customReason: result ? result.customReason || null : null,
        timezone:timeZone,
        rule: result || {},
        timeId: null,
        schedule,
        now: date === undefined,
        testDateTime: now,
        testDate: dayjs(now).format('YYYY-MM-DD'),
        testTimezone: dayjs(now).format('zzz'),
        testTime: dayjs(now).format('h:mma'),
      }
    }
  
    let startOfDay = now.format("YYYY-MM-DD ", { timeZone })
    let hoursCheck = result.times.filter((time) => {
      let startTime = dayjs(`${startOfDay}${time.startTime}`).tz(timeZone, true).format()
      let endTime = dayjs(`${startOfDay}${time.endTime}`).tz(timeZone, true).format()
      if (time.endTime === '00:00:00'){
        endTime = dayjs(endTime).add(1,'day').format()
      } 
      return now.isBetween(startTime, endTime, null, "[)")
    })
    let hours = hoursCheck[0] || {status:'closed', reason: 'afterhours',customReason: null}
    console.log(hours)
  return  {
    found: true,
    status: hours.status,
    reason: hours.reason,
    customReason: hours.customReason,
    timezone:timeZone,
    rule: result,
    schedule,
    timeId: hours.id,
    now: date === undefined,
    testDateTime: now,
    testDate: dayjs(now).format('YYYY-MM-DD'),
    testTime: dayjs(now).format('h:mma'),
    testTimezone: testTimezone || testDateTime ? testDateTime.substr(testDateTime.length-6) : dayjs(now).format('zzz'),

    }
    // console.log('sent')
  }
  

  export default checkHours