<template>
  <v-dialog transition="dialog-top-transition" max-width="400" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        <v-icon small>mdi-text-box-search</v-icon>
        Test Schedule
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary" dark>Test Schedule</v-toolbar>
      <v-card-text>
        <v-row>
          <v-col> 
            <field-date v-model="date" :options="{maxDays:730, minDays: 0}"></field-date></v-col>
          <v-col
            ><v-text-field
              v-model="time"
              label="Test Time"
              type="time"
              :hint="timezone"
              persistent-hint
              prepend-icon="mdi-clock"
            >
            </v-text-field
          ></v-col>
          <v-col><field-timezone v-model="timezone"></field-timezone></v-col>
          <div>
            <em>Note: Timezone will render as your local timezone when checking. It will convert the time for you.</em>
          </div>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text @click="cancel">Reset</v-btn>
        <v-spacer />
        <v-btn color="success" @click="change">Test</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import FieldDate from "../fields/FieldDate.vue";
import FieldTimezone from "../fields/FieldTimezone"

export default {
      components: {
    FieldDate,
    FieldTimezone,
  },
  data() {
    return {
      dialog: false,
        date: this.$dayjs().format("YYYY-MM-DD"),
        time: this.$dayjs().format("HH:mm"),
        timezone: "EST5EDT",
    };
  },
  props: ["value"],
  methods: {
    setTestNow(){
        this.date= this.$dayjs().format("YYYY-MM-DD")
         this.time= this.$dayjs().format("HH:mm")
         this.timezone= "EST5EDT"
    },
    change() {
      console.log(this.date)
        let dateTime = this.$dayjs(`${this.date} ${this.time}`)
            .tz(this.timezone, true)
            .format()
      console.log({dateTime})
      this.$emit("input", {dateTime, date: this.date, time: this.time, timezone:this.timezone});
      this.dialog = false
    },
    cancel() {
      this.setTestNow()
      this.dialog = false
            this.$emit("input", null);

    },
  },
};
</script>

<style>
</style>